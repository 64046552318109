import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import P2V1 from "./images/3090Demo.mp4";
import P1V1 from "./images/4090Stab.mp4";
import Modal from './modal';
import Blue from "./images/4090Blue.jpg";
import BlueFront from "./images/4090BlueFront.jpg";
import BlueCloseup from "./images/4090Closeup.jpg";
import BlueCloseup2 from "./images/4090Closeup2.jpg";
import Front from "./images/3090Front.jpg";
import Back from "./images/3090Back.jpg";
import Perspective1 from "./images/3090Perspective.jpg";
import Perspective2 from "./images/3090Perspective2.jpg";

//Stock Photo?
const sectionStyle1 = {
  backgroundImage: `url(${process.env.PUBLIC_URL}/MissionStatement2.jpg)`
};

const Home = () => {

  const [isModalVisible, setModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const handleSectionClick = (source) => {
    setCurrentImage(source);
    setModalVisible(true);
  };
  
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  return (
    <main className = "scroller">
      <section id="section1" className="section" style={sectionStyle1}>
        <div className="nonhovercontent">
          <h1 className = "missionTitle">Mission Statement</h1>
          <p className = "missionText desktop-only">At OKPC, our mission is to build meaningful connections through personalized, high-quality computers designed to empower our clients. We prioritize understanding the unique needs of each individual, offering tailored solutions that make technology more accessible and user-friendly. Rooted in a commitment to community and service, we believe in connection over profit, ensuring that every computer we build enhances the lives of those we serve, fostering trust and long-lasting relationships. From our local seniors to clients across the GTA, we aim to bridge the gap between people and technology with care, integrity, and excellence.</p>
          
          <p className="missionText mobile-only">
            At OKPC, we prioritize people over profits with our mission being to build meaningful connections..
          </p>

          <p className = "missionText desktop-only">Your tech should be as unique as you are, come to OKPC and let us build you the computer of your dreams. We will work with you every step of the way to make you feel confident in your choices by the end of the process.</p>
          
          <p className = "missionText mobile-only">Come to OKPC and let us build you the computer of your dreams.</p>

          <div>
            <Link className = "desLink" to="/about">Learn More</Link>
          </div>
        </div>
      </section>
      <section id="section2" className="section">
        <video autoPlay muted loop className = "P2V1">
          <source src = {P1V1} type = "video/mp4"/>
        </video>
        <div className="content">
          <h4 className = "titlePrelude">Computer A</h4>
          <h1 className = "glowText desktop-only">The Godlike</h1>
          <h1 className = "glowText mobile-only">Godlike</h1>
          <h2 className = "desktop-only">
            Our best: <span class = "highlight c1">Over-Kill Performance</span> & <span class = "highlight c3">Water Cooling</span>
          </h2>
          <p className = "P2text desktop-only">Launched in March 2024, the Godlike is our best example of ultimate performance. Powered by the Intel i9 14900KS and RTX 4090, it’s built to handle the most demanding gaming and rendering tasks with ease.</p>
          
          <p className = "P2text mobile-only">Powered by the Intel I9 14900KS and the RTX 4090, the Godlike is our best work for sheer performance </p>

          <p className = "P2text desktop-only">Featuring custom hardline watercooling with two 480mm and one 400mm radiator, plus 23 Corsair fans, the Godlike keeps temperatures below 60°C, even when overclocked.</p>
            
          <p className = "P2text mobile-only">With custom watercooling and 23 fans, it keeps temps well below 60°C, even while overclocked.</p>
          
          <p className = "P2text desktop-only">For those who demand the best, we can deliver unrivaled speed, power, and cooling. Come to us and experience performance at its peak.</p>
          
          <p className = "P2text mobile-only">For those who demand the best, we can deliver and then some! Experience performance at it's peak</p>
          <div className = "modalContainer">
            <div>
              <h3 className = "modalTitle">PHOTOS</h3>
            </div>

            <img 
              src = {Blue} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(Blue)} alt = 'Project 1 Modal 1'
              
            />

            <img 
              src = {BlueFront} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(BlueFront)} alt = 'Project 1 Modal 2'
              
            />

            <img 
              src = {BlueCloseup} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(BlueCloseup)} alt = 'Project 1 Modal 2'
              
            />

             <img 
              src = {BlueCloseup2} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(BlueCloseup2)} alt = 'Project 1 Modal 2'
              
            />
          </div>
        </div>
      </section>
      <section id="section3" className="section">
        <video autoPlay muted loop className = "P2V1">
          <source src = {P2V1} type = "video/mp4"/>
        </video>
        <div className="content">
          <h4 className = "titlePrelude">
            Computer B
          </h4>
          <h1 className="projectDemo desktop-only">The Discount</h1>
          <h1 className = "projectDemo mobile-only">Discount</h1>
          <h2 className = "desktop-only">
            Our Best: <span class = "highlight c2">Cable Management</span> & <span class = "highlight c1">Cost Efficiency</span>
          </h2>
          <p className = "P2text desktop-only">Built for just 1,823.25 CAD, the Discount showcases our best example of cost-efficiency and clean cable management. Despite the budget-friendly price, it features the powerful Aorus RTX 3090 Xtreme and AMD’s high-performance Ryzen 9 7900X3D CPU.</p>

          <p className = "P2text mobile-only">Made with only 1,823.25 CAD yet featuring an RTX 3090 and Ryzen 9 7900X3D, the Discount is our best work yet for cost-efficiency</p>

          <p className = "P2text">Every component except the thermal paste was purchased a discount. Proof that we can deliver high performance without breaking the bank.</p>
          
          <p className = "P2text mobile-only">For those on a budget, we'll go the extra mile just for you.</p>
          <div className = "modalContainer">
            <div>
              <h3 className = "modalTitle">PHOTOS</h3>
            </div>

            <img 
              src = {Front} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(Front)} alt = 'Project 2 Modal 1'
              
            />

            <img 
              src = {Back} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(Back)} alt = 'Project 2 Modal 2'
              
            />

            <img 
              src = {Perspective1} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(Perspective1)} alt = 'Project 2 Modal 3'
              
            />

            <img 
              src = {Perspective2} 
              className = "modalIcon" 
              onClick={() => handleSectionClick(Perspective2)} alt = 'Project 2 Modal 4'
              
            />
          </div>
        </div>
      </section>

      <Modal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        source={currentImage}
      />

    </main>
  );
};

export default Home;
