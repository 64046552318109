import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

//Stock Photo?
const sectionStyle1 = {
  backgroundImage: `url(${process.env.PUBLIC_URL}/fromscratch.png)`
};

//The OK | Computer A
const sectionStyle2 = {
  backgroundImage: `url(${process.env.PUBLIC_URL}/2080Front.jpeg)`
};

//3090 Project
const sectionStyle3 = {
  backgroundImage: `url(${process.env.PUBLIC_URL}/stockRepair.png)`
};

const Services = () => {
  return (
    <main>
      <section id="section1" className="section" style={sectionStyle1}>
        <div className="nonhovercontent">
          <h1 className = "serviceTitle">PC Building</h1>
          <p className = "serviceText desktop-only">At OKPC, we believe your computer should work for you, not the other way around. Whether you’re a gamer, content creator, or business professional, we offer personalized computer builds designed to meet your unique needs. From the selection of premium hardware to custom cooling solutions, we handle everything, ensuring your PC is optimized for performance, style, and durability.</p>
          
          <p className = "serviceText mobile-only">At OKPC, we believe your computer should work for you. We offer custom builds tailored to your needs. From premium hardware to custom cooling, we ensure your PC is built for peak performance, style, and durability.</p>

          <p className = "serviceText desktop-only">Our process is simple: tell us your requirements, and we’ll bring your vision to life. We don’t just assemble parts; we create systems that are reliable, powerful, and built to last. With OKPC, you get a high-quality, custom machine that matches your workflow, style, and ambitions.</p>

          <p className = "serviceText mobile-only">Our process is simple: share your needs, and we’ll bring your vision to life. At OKPC, we don’t just assemble parts; we craft systems tailored to your workflow, style, and ambitions.</p>

          <p className = "serviceText desktop-only">It’s not about making a sale; it’s about making a connection. Experience the difference of working with a company that puts people first, offering expert advice and unparalleled support, from concept to delivery.</p>
          
          <p className = "serviceText mobile-only">It’s about connection, not just sales. Come to us and experience the difference with a company that puts people first.</p>

          <Link className = "desLink" to="/contact">Contact Us</Link>
        </div>
      </section>
      <section id="section2" className="section" style={sectionStyle2}>
        <div className="nonhovercontent">
          <h1 className = "serviceTitle">Cable Management</h1>
          <p className = "serviceText">At OKPC, we know that a powerful computer deserves a clean and organized interior. Our professional cable management service ensures your PC runs at peak performance while looking as polished as it performs. With meticulously routed cables, we optimize airflow and reduce clutter, keeping your system cool and making future upgrades easy.</p>

          <p className = "serviceText">Whether you're building from scratch or improving an existing setup, we take care of every detail, hiding cables for a sleek, professional appearance. Enjoy improved cooling efficiency and a neat, organized PC that’s as impressive on the inside as it is on the outside.</p>
        </div>
      </section>
      <section id="section3" className="section" style={sectionStyle3}>
        <div className="nonhovercontent">
          <h1 className = "serviceTitle">Computer Repair</h1>
          <p className = "serviceText desktop-only">At OKPC, we know how essential your computer is to your daily life, and when something goes wrong, you need quick, reliable repairs. Our expert technicians are here to help, whether it’s a hardware issue, software glitch, or performance problem. We specialize in diagnosing and fixing a wide range of computer problems, from hardware malfunctions to virus removal, system optimization, and more.</p>

          <p className = "serviceText mobile-only">At OKPC, we understand how vital your computer is. When issues arise, our expert technicians provide quick, reliable repairs for hardware, software, and performance problems. From malfunctions to virus removal and system optimization, we’ve got you covered.</p>
          
          <p className = "serviceText desktop-only">We treat every computer as if it’s our own, delivering fast, professional service with a personal touch. No hidden fees, no unnecessary repairs – just honest advice and quality work. Let us help you get back to what matters most.</p>

          <p className = "serviceText mobile-only">We treat every computer like our own, offering fast, professional service with a personal touch. No hidden fees, no unnecessary repairs—just honest advice and quality work to get you back to what matters most.</p>

          <p className = "serviceText">Also, your first repair service is completely free! We will not charge you for any labor fees, but you must cover costs to acquire replacement components if necessary.</p>
        </div>
      </section>
    </main>
  );
};

export default Services;