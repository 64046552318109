import React from 'react';
import '../styles.css';

const Footer = () => {
  return (
    <footer>
      <p className = "footerText">&copy; 2024 OKPC. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
