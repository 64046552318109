import React from 'react';
import { Link } from 'react-router-dom';
import cLogo from "../images/Logo1.png";
import bLogo from "../images/Logo2.png";
import '../styles.css';
import { useState } from 'react';

const Header = () => {
  const [menuClose, setMenuOpen] = useState(false);
  const toggleMenu = () => {setMenuOpen(!menuClose);}

  return (
    <header>
      <nav>
        <div className = "mobileHeader">
          <button className = "dropMenuButton" onClick={toggleMenu}>&#9776;</button>
          <Link className = "circleLogo2Container" to="/">
            <img src = {cLogo} className = "circleLogo2" alt = "Circle Logo"/>
          </Link>
        </div>
        <ul className={menuClose ? "preDropMenu": "dropMenu"}>
          <img src = {cLogo} className = "circleLogo" alt = "Circle Logo"/>
          <li className = {menuClose ? "menuClose": "menuOpen"}><Link to="/">Home</Link></li>
          <li className = {menuClose ? "menuClose": "menuOpen"}><Link to="/services">Services</Link></li>
          <li className = {menuClose ? "menuClose": "menuOpen"}><Link to="/about">About Us</Link></li>
          <li className = {menuClose ? "menuClose": "menuOpen"}><Link to="/contact">Contact Us</Link></li>
          <img src = {bLogo} className = "barLogo" alt = "Bar Logo" id = "right"/>
        </ul>
      </nav>
    </header>
  );
};

export default Header;